<template>
  <div class="home">
    <div class="flex-container">
      <v-container>
        <LoadingBounce v-if="loading" />

        <div v-if="invalidRequestID" class="mt-12 primary--text text-center">
          <p class="text-h4">Sorry!</p>
          <p>
            We're unable to proceed with your payment. This could be because the
            link you clicked has expired, or has been cancelled by your agent. 
          </p>
          <p>
            Please contact them directly for further assistance if you cannot
            access your payment details.
          </p>
          <p v-if="errorMessage"><strong>Ref: </strong>{{ errorMessage }}</p>
          <p v-else><strong>Ref: </strong>No request ID detected</p>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import LoadingBounce from "@/components/LoadingBounce.vue";
import { API } from "aws-amplify";

export default {
  name: "homepage",
  components: {
    LoadingBounce,
  },
  mounted() {
    if (this.$route.query.card) {
      this.$store.commit("SET_DIRECT_CARD_PAYMENT", true);
    }
    if (this.$route.query.requestid) {
      this.loading = true;
      const requestID = this.$route.query.requestid;
      const cardPayment = this.$route.query.cardPayment;
      if(cardPayment){
        this.$store.commit("SET_CARD_ONLY_PAYMENT", cardPayment);
      }
      if (requestID.length < 8) {
        //Is genereic payment request
        this.$store.commit("SET_GENERIC_REQUEST_ID", requestID);
      } else this.$store.commit("SET_REQUEST_ID", requestID);
      this.getPaymentDetails(requestID);
      this.getBankList();
    } else {
      // no request id
      this.setInvalidRequestMsg();
    }
  },
  data() {
    return {
      clientName: "letmcletting", // redundant
      loading: true,
      invalidRequestID: false,
      errorMessage: "",
    };
  },
  methods: {
    setInvalidRequestMsg() {
      setTimeout(() => {
        this.loading = false;
        this.invalidRequestID = true;
        if(this.$store.state.theme === 'calmonypay'){
          window.open("https://calmony.co/calmonypay", "_self")
        }
      }, 1500);
    },
    getPaymentDetails(requestID) {
      API.get("paymentAppRest", `/details?requestID=${requestID}`).then(
        (res) => {
          if (res.status === "200") {
            if (res.details.error) {
              // error message returned regarding id Request
              this.loading = false;
              this.invalidRequestID = true;
              this.errorMessage = res.details.error;
            } else {
              // all good to go
              this.$store.commit("SET_PAYMENT_DETAILS", res.details);
              const companyMarketingName =
                this.$store.state.paymentDetails.Company.split(" ").join("");

              // Set requestID from the result incase we have a new one:-
              if (res.details.Request.Reference) {
                this.$store.commit(
                  "SET_REQUEST_ID",
                  res.details.Request.Reference
                );
              }

              API.get("paymentAppRest", `/company/cardpayment/allowed?clientName=${res.details.CompanyShortName}`).then(
                (permissionRes => {
                  if(permissionRes.status === "200")
                    this.$store.commit("SET_CARD_PERMISSIONS", permissionRes.data);
                    console.log(permissionRes);
                })
              )

              // Check if oneOff payment OR STO

              if(this.$store.state.cardOnly){
                this.$router.push({
                  name: "PaymentDetails",
                  params: {
                    reference: this.$route.query.requestid,
                    company: companyMarketingName,
                  },
                });
              }

              else if (res.details.Request.Frequency === "OneOff") {
                // one off payment
                this.$router.push({
                  name: "PaymentHomepage",
                  params: {
                    reference: this.$route.query.requestid,
                    company: companyMarketingName,
                  },
                });
              } else {
                // standing order payment
                this.$router.push({
                  name: "StandingOrderHomepage",
                  params: {
                    reference: this.$route.query.requestid,
                    company: companyMarketingName,
                  },
                });
              }
            }
          } else {
            // something failed in lambda api call
            this.loading = false;
            this.invalidRequestID = true;
          }
        }
      );
    },
    getBankList() {
      API.get("paymentAppRest", `/banks`).then((res) => {
        this.$store.commit("SET_BANK_LIST", res.banks);
      });
    },
  },
};
</script>
